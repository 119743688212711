import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';

import { FirebaseContext } from './Firebase'
import 'firebase/firestore'
import 'firebase/analytics'
import LineThumb from './LineThumb'
import NewRacingLine from './NewRacingLine'
import ProfileThumb from './ProfileThumb'
import LikeButton from './LikeButton'

export default (props) => {
  const auth = useContext(FirebaseContext)
  const firebase = auth.app
  const linesDb = firebase.firestore().collection(`lines`)
  const [lines, setLines] = useState(null)
  const {
        wrapperClass = "post-feed horizontal-feed",
        publicLinesOnly = true, 
        closedLinesOnly = true, 
        oneRacingLinePerTrack = true,
        showProfilePhoto = true,
        showVisibility = false,
        showLike = false,
        alwaysShowNewLine = false,
        filterTrack,
        filterUser, 
        limit = 5,
        onNewLine = () => location.href = "/racing-line-editor",
        setLine
    } = props

  useEffect(() => {
    load()
    console.log("props changed: ", props)
  }, [filterTrack, filterUser])

  const load = async () => {
    try {

        let query = linesDb
        if(publicLinesOnly) query = addWhereToQuery(query, ["visibility", "==", "public"])
        if(closedLinesOnly) query = addWhereToQuery(query, ["isCloseDetected","==", true])
        if(filterUser) query = addWhereToQuery(query, ["user", "==", filterUser])
        if(filterTrack) query = addWhereToQuery(query, ["name", "==", filterTrack])
        const linesDbRes = await query.orderBy("created", "desc")
                .limit(+limit)
                .get()

        let lines = linesDbRes.docs.map(doc => {
            return { id: doc.id, ...doc.data() }
        })

        if(oneRacingLinePerTrack) lines = lines.filter((value, index, array) => 
                                                                index === 0 || value.name != array[index-1].name)
        setLines(lines)
        console.log("Lines found: ", lines)
    }
    catch(error) {
        console.error("Error loading document: ", error);
    }
  }

  const addWhereToQuery = (query, filter) => {
    if(filter && filter.length == 3){
        return query.where(...filter)
    }
  }

  const onClick = (e, line) => {
    if(typeof setLine !== "function") return

    setLine(line)
    e.preventDefault()
  }

  return (
    <div className={wrapperClass}>
        {(alwaysShowNewLine || !lines || lines.length == 0) && <NewRacingLine onNewLine={onNewLine} />}
        {lines && lines.map(line =>
                <article key={line.id} className="post-card track-card">
                        <div className="post-card-content track-card-content">    
                              <a className="post-card-image-link track-card-image" href={`${line.url}/r/${line.id}`} onClick={e => onClick(e, line)}>
                                      <LineThumb points={line.points} width={200} height={130}></LineThumb>
                              </a>
                              <header className="post-card-header track-card-header">
                                  {!filterTrack && <h2 className="post-card-title track-card-title">{line.name}</h2>}
                                  <span className="post-card-tags track-card-tags">
                                      {line.vehicle && line.vehicle != "" && line.vehicle + " · "}
                                      {moment(line.created.toDate()).format("MMM, YYYY")}
                                  </span>
                                  {showVisibility && <span className="post-card-tags track-card-tags"> {line.visibility }</span>}
                              </header>
                              <footer className="post-card-meta">
                                  {showProfilePhoto && <ProfileThumb uid={line.user} />}
                                  {showLike && <LikeButton 
                                      lineId={line.id}/>}
                              </footer>
                        </div> 
                </article>
        )}
    </div>
  )
};
