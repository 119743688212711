import React, { useState, useEffect, useContext } from 'react';
import LineCanvas from './LineCanvas';

export default (props) => {
    const canvasRef = React.createRef()
    
    useEffect(() => {
        if(!props.points || props.points.length <= 1) return

        const lineCanvas = new LineCanvas(canvasRef.current)
        const points = props.points.map(p => ({...convert(p)}))
        lineCanvas.draw({
            points, 
            fitBounds: true, 
            scale: 0.8,
            accStrokeWidth: 4,
            accStrokeGradient: {
                stops: ['#57a86f', '#238a39']
            },
            brakeStrokeColor: "#d66d6d",
            coastStrokeColor: "#bcdf5c",
            arrowSize: 10,
            arrowStrokeWidth: 3,
            arrowStrokeColor: "#238a39",
            coloredStrokeWidth: 3,
            shadowColor: "#eee",
            shadowBlur: 1,
            shadowOffset: {x: 5, y: 5}
        })

        return () => lineCanvas.remove()
    }, [props.points])

    const convert = o => {
        var y = ((-1 * o.lat) + 90) * (props.height / 180)
        var x = (o.lng + 180) * (props.width / 360)
        return {x, y, ...o}
    }

    return (
        <canvas ref={canvasRef} width={props.width} height={props.height}></canvas>
    )
};
