import React, { useEffect, useState, useContext } from 'react';

import './LikeButton.css'

import { FirebaseContext } from './Firebase'
import 'firebase/firestore'
import 'firebase/analytics'
import { Heart } from '@styled-icons/feather/Heart'


export default (props) => {
    const auth = useContext(FirebaseContext)
    const firebase = auth.app
    const {requireAuth} = auth
    const signInAnonymouslyAndGetUser = auth.signInAnonymouslyAndGetUser
    const linesDb = firebase.firestore().collection(`lines`)
    const user = auth.user

    const {lineId} = props
    const [likes, setLikes] = useState(null)
    const [isLikedByUser, setIsLikedByUser] = useState(false)

    const loadLikesAndListen = lineId => {
        try {
            return linesDb.doc(lineId).onSnapshot(async doc => {
              if (!doc.exists) return
    
              const data = doc.data()
              const likes = data.likes
              if(!likes) {
                  setLikes(0)
              }

              setLikes(likes)
            })
        }
        catch(error) {
            console.error("Error loading document: ", error);
        }
      }

    useEffect(() => {
        if(!lineId) return

        const unsubscribe = loadLikesAndListen(lineId)
        return unsubscribe
    }, [lineId])

    useEffect(() => {
        const checkIfIsLiked = async () => {
            setIsLikedByUser(likes && user && likes.includes(user.uid))
        }
        checkIfIsLiked()
    }, [likes])

    const saveLikesToDB = async likes => {
        // Save likes to db
        try {
            const doc = linesDb.doc(lineId)
            await doc.set({ likes }, { merge: true });
    
            console.log("Likes written to id: ", doc.id);
        }
        catch(error) {
            console.error("Error updating document: ", error);
        }
    }
    
    const onToggleLike = currentUser => {
        console.log("toggle like for uid", currentUser.uid)

        if(isLikedByUser) {
            // Remove user from likes array
            const likesWithoutUser = likes.filter(item => item !== currentUser.uid)
            setLikes(likesWithoutUser)
            saveLikesToDB(likesWithoutUser)
        }
        else {
            // Add user to likes array
            const likesWithUser = likes ? [...likes] : []
            likesWithUser.push(currentUser.uid)
            setLikes(likesWithUser)
            saveLikesToDB(likesWithUser)
            firebase.analytics().logEvent('like', {lineId});
        }

    }

    return (
        <button onClick={() => requireAuth(onToggleLike)} className={isLikedByUser ? "like blue-bg" : "like"}>
            <Heart className="h-4"/>
            {likes && likes.length > 0 && <span>{likes.length}</span>}
            <span>Like</span>
        </button>
    )
}