import React from 'react';
import arrow from '../../images/arrow.svg'

const NewRacingLine = props => {
    const { onNewLine } = props

    return ( 
        <article className="post-card track-card">
            <div className="post-card-content track-card-content">   
                <a onClick={onNewLine} className="h-full w-full flex flex-col items-center" href="#">
                    <img src={arrow} alt="Arrow logo" className="w-12 m-3" />
                    <span>Draw Racing Line</span>
                </a>
            </div>      
        </article> 
    )
}
 
export default NewRacingLine;