
import * as paper from 'paper';

if(typeof window !== "undefined") window.paper = paper;

class LineCanvas {
  constructor(canvas, setCloseDetected, setImage) {
    this.paperScope = new paper.PaperScope();
    this.paperScope.setup(canvas);
    this.setCloseDetected = setCloseDetected;
    this.setImage = setImage;
    this.canvas = canvas;
  }

  remove() {
    this.paperScope.remove()
  }

  draw(props) {
    const points = props.points
    if(props.readOnly === undefined) props.readOnly = true
    const scale = props.scale || 1

    this.paperScope.activate()
    this.paperScope.project.activeLayer.removeChildren();

    if(!points || !points.length) return;

    const path = new this.paperScope.Path(...points);
    path.strokeWidth = props.accStrokeWidth || 6 * scale
    path.strokeColor = {
        gradient: props.accStrokeGradient || {
            stops: ['#70bd74', '#a4d037']
        },
        origin: path.bounds.topLeft,
        destination: path.bounds.bottomRight
    };
    path.shadowColor = props.shadowColor || "#333"
    path.shadowBlur = props.shadowBlur || 4,
    path.shadowOffset = props.shadowOffset || {x: 2, y: 2}

    const to = !props.readOnly && path.segments.length > 2 
                ?  path.segments.length - 2 
                : path.segments.length
    path.smooth({  
        factor: 0.5,
        type: 'catmull-rom',
        to 
    });
    path.fullySelected = !props.readOnly;

    if(props.fitBounds) path.fitBounds(this.paperScope.view.bounds)

    // draw an arrow to show the direction of the path 
    if(path.segments.length > 1) {
        const startPoint = path.segments[0].point
        const endPoint = path.segments[1].point
        const vector = endPoint.subtract(startPoint)
        const arrow = vector.normalize(props.arrowSize || 25 * scale)

        const direction = new this.paperScope.Path([
            startPoint.add(arrow.rotate(150)),
            startPoint,
            startPoint.add(arrow.rotate(-150))
        ])
        direction.strokeWidth = props.arrowStrokeWidth || 8 * scale;
        direction.strokeCap = 'round';
        direction.strokeColor = props.arrowStrokeColor || '#a4d037';
    }

    // Draw braking and coast lines on top of the racing line
    points.forEach((p, pIndex) => {
        if(!p.type || !pIndex) return

        const from = path.segments[pIndex - 1]
        const to = path.segments[pIndex]
        let color

        switch(p.type) {
            case "brake": 
                color = props.brakeStrokeColor || "#b65656"
                break
            case "coast": 
                color = props.coastStrokeColor || "yellow"
                break
            default:
            return
        }
        this.addColoredPath(from, to, color, scale, props)
    });

    // Detect if racing path is closed
    const isClosed = path.segments.length > 5 
                    && path.firstSegment.point.getDistance(path.lastSegment.point) < 30
    if(typeof this.setCloseDetected === "function") this.setCloseDetected(isClosed)

    this.paperScope.view.scale(scale);
    this.paperScope.view.draw();
    this.buildImage(props.readOnly)
  }

  addColoredPath(from, to, color, scale, props) {
    const coast = new this.paperScope.Path([from, to])
    coast.strokeWidth = props.coloredStrokeWidth || 4 * scale;
    coast.strokeColor = color;
  }

  buildImage(readOnly) {
    if(typeof this.setImage !== "function" || !readOnly) return
    this.setImage(this.canvas.toDataURL())
  }
  
}

export default LineCanvas;
